'use client';

import Cookies from 'js-cookie';
import { useEffect } from 'react';

const getQueryParams = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  return JSON.stringify(params);
};

export default function useLgtCookie() {
  useEffect(() => {
    Cookies.set('lgt_campaign_query_params', getQueryParams(), {
      expires: 3,
      path: '/',
    });

    Cookies.set('lgt_referer_url', window.location.href, {
      expires: 3,
      path: '/',
    });
  }, []);
}
