import { PropsWithChildren, useEffect } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import DynamicContent from 'components/DynamicContent';
import PageNotFound from 'components/PageNotFound';

import { getWebsiteUrl } from 'lib';
import { Page } from 'interfaces/cms/page';
import { baseUrl } from 'lib/constants';

export interface PageProps {
  page: Page;
  previewMode?: boolean;
  hrefLang?: string[];
  tenant?: string;
}

function PageComponent({
  page,
  hrefLang,
  children,
}: PropsWithChildren<Readonly<Omit<PageProps, 'previewMode'>>>) {
  const { asPath, defaultLocale } = useRouter();

  const mainClasses = 'w-full p-0 block flex-1 mr-auto my-0';

  useEffect(() => {
    if (page?.bgPattern) {
      document?.querySelector('body')?.classList.add('bg-pattern');
    } else {
      document?.querySelector('body')?.classList.remove('bg-pattern');
    }
  }, [page]);

  if (page?.slug === 'page-not-found') {
    return (
      <main className={mainClasses}>
        <PageNotFound />
      </main>
    );
  }

  return (
    <>
      <Head>
        {hrefLang?.map((item) => {
          return (
            <link
              key={item}
              rel="alternate"
              hrefLang={item}
              href={`${baseUrl}${item !== defaultLocale ? '/' + item : ''}${
                asPath.split('?')[0]
              }`}
            />
          );
        })}

        <link
          rel="alternate"
          hrefLang="x-default"
          href={getWebsiteUrl(asPath)}
        />
      </Head>
      {page.content && page?.content?.length > 0 && (
        <main className={mainClasses}>
          <DynamicContent content={page.content} />
          {children}
        </main>
      )}
    </>
  );
}

export default PageComponent;
